<template>
  <div class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_1_3") }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>
      <div class="row justify-content-center mt-5">
        <div class="category-navigate">
          <ul>
            <li class="btn btn-primary btn-lg">
              <a href="#" @click="goAnchor('.block1')">{{
                $t("section_1.title")
              }}</a>
            </li>
            <li class="btn btn-primary btn-lg">
              <a href="#" @click="goAnchor('.block2')">{{
                $t("section_2.title")
              }}</a>
            </li>
            <li class="btn btn-primary btn-lg">
              <a href="#" @click="goAnchor('.block3')">{{
                $t("section_3.title")
              }}</a>
            </li>
            <li class="btn btn-primary btn-lg">
              <a href="#" @click="goAnchor('.block4')">{{
                $t("section_4.title")
              }}</a>
            </li>
          </ul>
        </div>
      </div>
      <section class="blog-area pb-70">
        <div class="container">
          <div class="facility-section block1">
            <div id="food">
              <h1>{{ $t("section_1.title") }}</h1>
              <hr size="1" />
            </div>
            <div class="row">
              
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/beef.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        <i class="fas fa-utensils"></i
                        >{{ $t("section_1.item_2.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_2.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/hi-life.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        <i class="fas fa-store"></i
                        >{{ $t("section_1.item_3.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_3.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/erabronze.gif"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        <i class="fas fa-store"></i
                        >{{ $t("section_1.item_4.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_4.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/tea.gif"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        <i class="fas fa-mug-hot"></i
                        >{{ $t("section_1.item_5.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_5.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/movenpick.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        <i class="fas fa-mug-hot"></i
                        >{{ $t("section_1.item_6.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_6.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="row no-gutters flex-row-reverse">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/messe.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        <i class="fas fa-utensils"></i
                        >{{ $t("section_1.item_7.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_1.item_7.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="facility-section block2">
            <div id="product">
              <h1>{{ $t("section_2.title") }}</h1>
              <hr size="1" />
            </div>
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <div class="card h-100">
                  <img
                    src="~assets/project_images/facility/art1.jpg"
                    class="card-img-top"
                    alt="Image"
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      {{ $t("section_2.item_1.title") }}
                    </h5>
                    <p
                      class="card-text text-left"
                      v-html="$t('section_2.item_1.content_1')"
                    ></p>
                  </div>
                  <div class="card-footer text-left">
                    <small
                      class="text-muted"
                      v-html="$t('section_2.item_1.content_2')"
                    >
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="card h-100">
                  <img
                    src="~assets/project_images/facility/art2.gif"
                    class="card-img-top"
                    alt="Image"
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      {{ $t("section_2.item_2.title") }}
                    </h5>
                    <p
                      class="card-text text-left"
                      v-html="$t('section_2.item_2.content_1')"
                    ></p>
                  </div>
                  <div class="card-footer text-left">
                    <small
                      class="text-muted"
                      v-html="$t('section_2.item_2.content_2')"
                    >
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="card h-100">
                  <img
                    src="~assets/project_images/facility/artFuture.gif"
                    class="card-img-top"
                    alt="Image"
                  />
                  <div class="card-body">
                    <h5 class="card-title">
                      {{ $t("section_2.item_3.title") }}
                    </h5>
                    <p
                      class="card-text text-left"
                      v-html="$t('section_2.item_3.content_1')"
                    ></p>
                  </div>
                  <div class="card-footer text-left">
                    <small
                      class="text-muted"
                      v-html="$t('section_2.item_3.content_2')"
                    >
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="facility-section block3">
            <div id="public-art">
              <h1>{{ $t("section_3.title") }}</h1>
              <hr size="1" />
            </div>
            <div class="row">
              <!-- 安益國際展覽高雄服務部 -->
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/interplan.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_3.item_1.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_3.item_1.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 常宥科技 -->
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/Changyou.gif"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_3.item_2.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_3.item_2.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 紳運有限公司 -->
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/Forwarders.gif"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_3.item_3.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_3.item_3.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 奕達運通有限公司 -->
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/eurotran.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_3.item_4.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_3.item_4.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 誠銓環境 -->
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/cqebc.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_3.item_5.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_3.item_5.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 先鋒保全 -->
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img
                      src="~assets/project_images/facility/pioneer.jpg"
                      alt="Image"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body text-left">
                      <h5 class="card-title">
                        {{ $t("section_3.item_6.title") }}
                      </h5>
                      <p
                        class="card-text"
                        v-html="$t('section_3.item_6.content')"
                      ></p>
                      <p class="card-text">
                        <small class="text-muted"></small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="facility-section block4">
            <div id="public-art">
              <h1>{{ $t("section_4.title") }}</h1>
              <hr size="1" />
            </div>
            <div class="row">
              <div class="col-lg-4 col-sm-6">
                <div class="single-blog-post borrow-border-div">
                  <img
                    src="~assets/project_images/facility/InfoCenter.jpg"
                    alt="Image"
                  />

                  <div class="blog-content">
                    <h3>
                      {{ $t("section_4.item_1.title") }}
                    </h3>
                    <p>
                      {{ $t("section_4.item_1.content") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="single-blog-post borrow-border-div">
                  <img
                    src="~assets/project_images/facility/VIP.gif"
                    alt="Image"
                  />

                  <div class="blog-content">
                    <h3>
                      {{ $t("section_4.item_2.title") }}
                    </h3>
                    <p>
                      {{ $t("section_4.item_2.content") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="single-blog-post borrow-border-div">
                  <img
                    src="~assets/project_images/facility/PressCenter.jpg"
                    alt="Image"
                  />

                  <div class="blog-content">
                    <h3>
                      {{ $t("section_4.item_3.title") }}
                    </h3>
                    <p>
                      {{ $t("section_4.item_3.content") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="single-blog-post borrow-border-div">
                  <img
                    src="~assets/project_images/facility/Breastfeeding.jpg"
                    alt="Image"
                  />

                  <div class="blog-content">
                    <h3>
                      {{ $t("section_4.item_4.title") }}
                    </h3>
                    <p>
                      {{ $t("section_4.item_4.content") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="single-blog-post borrow-border-div">
                  <img
                    src="~assets/project_images/facility/OrganizersOffice.gif"
                    alt="Image"
                  />
                  <div class="blog-content">
                    <h3>
                      {{ $t("section_4.item_5.title") }}
                    </h3>
                    <p>
                      {{ $t("section_4.item_5.content") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6">
                <div class="single-blog-post borrow-border-div">
                  <img
                    src="~assets/project_images/facility/FirstAid.gif"
                    alt="Image"
                  />

                  <div class="blog-content">
                    <h3>
                      {{ $t("section_4.item_6.title") }}
                    </h3>
                    <p>
                      {{ $t("section_4.item_6.content") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SmoothScroll from "smooth-scroll";
export default {
  name: "FacilityBody",
  data() {
    return {
      selected: null,
      options: [
        {
          value: null,
          text: "預計的人數 / 攤位數",
        },
        {
          value: "a",
          text: "This is First option",
        },
        {
          value: "b",
          text: "Selected Option",
        },
        {
          value: {
            C: "3PO",
          },
          text: "This is an option with object value",
        },
        {
          value: "d",
          text: "This one is disabled",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    goAnchor(selector) {
      const scroll = new SmoothScroll();
      const anchor = document.querySelector(selector);
      scroll.animateScroll(anchor, null, {
        //updateURL: false,
        speed: 800,
      });
    },
  },
};
</script>

<style scoped>
.card-title {
  font-size: 1.5em;
}
.card-title i {
  margin-right: 10px;
}
.solutions-area.section-width .container {
  max-width: 1000px;
}

.facility-section {
  margin-top: 40px;
  text-align: center;
}

.blog-content {
  text-align: left;
}

.single-blog-post .blog-content h3 {
  margin-bottom: 13px;
  font-weight: 500;
}

.card img {
  width: 320px;
  height: 320px;
  object-fit: cover;
}
.card .row div:first-child {
  overflow: hidden;
}
.card img {
  transform: scale(1, 1);
  transition: all 1s ease-out;
}
.block1 .card:hover img,
.block3 .card:hover img {
  transform: scale(1.1, 1.1);
}
.block2 .card img {
  width: 100%;
}

.card-footer {
  font-size: 16px;
}

.single-blog-post img {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

:target:before {
  content: "";
  display: block;
  height: 80px;
}

hr {
  background-color: #000000;
  height: 3px;
  border: none;
  margin: 30px 0;
}

.cover-image {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.fs-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
