import Vue from "vue";
import VueRouter from "vue-router";
import VueCarousel from "vue-carousel";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import BackToTop from "vue-backtotop";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";
import App from "./App.vue";
import "./assets/css/custom.scss";
import "./assets/fonts/fontawesome-free-5.15.3/css/all.css";
import { router } from "./router";
import VueAxios from "vue-axios";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import i18n from "./assets/plugins/i18n"; // 引入語言套件
import VueMeta from "vue-meta"; // meta SEO
import VueKinesis from "vue-kinesis";
import "bootstrap-table/dist/bootstrap-table.js";
//datatables export
import jQuery from "jquery";
window.jQuery = jQuery;
import "bootstrap-table/dist/bootstrap-table.min.css";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "bootstrap-icons/font/bootstrap-icons.css";

//請求預設帶入Authorization
axios.defaults.headers.get["Authorization"] = "Bearer test123";
axios.defaults.headers.post["Authorization"] = "Bearer test123";
// axios.defaults.withCredentials = true

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(BackToTop);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueMeta);
Vue.use(VueKinesis);
Vue.use(VueReCaptcha, {
  siteKey: "6Le0jtYkAAAAAOK7N5tHyB0RNMQf_3dJ8aTw7vav",
  loaderOptions: {
    autoHideBadge: true,
  },
});

if (process.env.VUE_APP_DEBUG == "TRUE") {
  Vue.config.debug = true;
  Vue.config.devtools = true;
} else {
  Vue.config.debug = false;
  Vue.config.devtools = false;
}
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
