<template>
  <div
    id="contactus"
    class="solutions-area section-width ptb-100 faq-contact-area"
  >
    <div class="container wow fadeInUp">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_4_4") }}</h2>
      </div>

      <b-form id="contactForm">
        <div class="text-center mb-4">
          <span class="fa-stack fa-3x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-envelope fa-stack-1x fa-inverse"></i>
          </span>
        </div>
        <b-form-group :label="$t('form.item_1.label')">
          <b-form-input
            :placeholder="$t('form.item_1.example')"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form.item_2.label')">
          <b-form-input
            :placeholder="$t('form.item_2.example')"
            type="email"
            required
            id="email"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('form.item_3.label')">
          <VuePhoneNumberInput
            v-model="phone"
            required
            :translations="{
              countrySelectorLabel: $t('cc'),
              phoneNumberLabel: '0912345678',
              example: $t('ex'),
            }"
            :placeholder="$t('form.item_3.example')"
            @update="is_valid_phone"
          />
          <input
            type="hidden"
            id="phone"
            v-model="formattedNumber"
            class="form-control"
          />
        </b-form-group>

        <b-form-group :label="$t('form.item_4.label')">
          <b-form-input :placeholder="$t('form.item_4.example')"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('form.item_5.label')">
          <b-form-textarea
            id="textarea"
            :placeholder="$t('form.item_5.example')"
            rows="5"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <div class="col-lg-12 col-md-12 mb-3">
          <div class="row">
            <div class="col-lg-4"></div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4"></div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <button
            type="button"
            class="default-btn page-btn btn btn-warning"
            :disabled="isSubmit"
            @click="submitForm"
          >
            <b-spinner v-if="loadingVar"></b-spinner>
            {{ $t("btn") }}
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script scoped>
//import VueRecaptcha from "vue-recaptcha";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  name: "ContactUsBody",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      isSubmit: false,
      apiUrl: `${process.env.VUE_APP_API_URL}/mailer/`,
      passReCAPTCHA: false,
      clientUid: "",
      title: "聯絡我們",
      loadingVar: false,
      token: "",
      phone: "",
      formattedNumber: "",
      valid_phone: false,
    };
  },
  activated() {
    if (this.$route.hash === "#contactus") {
      setTimeout(() => {
        const element = document.querySelector(this.$route.hash);
        element.scrollIntoView({ behavior: "smooth" });
      }, 50);
    }
  },
  methods: {
    is_valid_phone(payload) {
      this.formattedNumber = payload.formattedNumber;
      this.valid_phone = payload.isValid;
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      this.token = await this.$recaptcha("login");
      if (this.token) {
        this.isSubmit = false;
      }
      // Do stuff with the received token.
      return;
    },
    // isPassReCAPTCHA(val) {
    //   let api = `${this.apiUrl}recapcha`;
    //   const postParams = new URLSearchParams();
    //   postParams.append("g-recaptcha-response", val);
    //   let postConfig = {
    //     withCredentials: true,
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //   };
    //   this.axios
    //     .post(api, postParams.toString(), postConfig)
    //     .then((response) => {
    //       if (response["data"] == "" || response["data"] == undefined) {
    //         alert("驗證失敗 請重新驗證");
    //       } else {
    //         this.passReCAPTCHA = true;
    //         this.clientUid = response["data"];
    //         this.isSubmit = false;
    //       }
    //     });
    // },
    async submitForm() {
      await this.recaptcha();
      if (!this.formVerify()) return;
      this.isSubmit = false;
      this.loadingVar = true;
      let data = {};
      let formInputData = document.querySelectorAll(".form-group");
      for (let i = 0; i < formInputData.length; i++) {
        let keyStash = formInputData[i].querySelector(".col-form-label")
          .innerText;
        let valStash = "";
        valStash = formInputData[i].querySelector(".form-control").value;

        data[keyStash] = {
          group: "group1",
          content: valStash,
        };
      }
      let api = `${this.apiUrl}sendmail`;
      const postParams = new URLSearchParams();
      postParams.append("MailTo", JSON.stringify(["info@kecc.com.tw"]));
      postParams.append(
        "MailTocc",
        JSON.stringify(["marketing.kec@gmail.com", "marcus@cteam.com.tw"])
      );
      postParams.append("Title", this.title);
      postParams.append("Items", JSON.stringify(data));
      postParams.append("FormId", 11);
      postParams.append("ClientUid", this.clientUid);
      postParams.append("Token", this.token);
      let postConfig = {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      this.axios
        .post(api, postParams.toString(), postConfig)
        .then((response) => {
          if (parseInt(response["data"]) == 1) {
            this.$swal({
              icon: "success",
              title: "送出表單成功",
            });
          } else {
            this.$swal({
              icon: "error",
              title: "表單送出失敗",
              text: "若重複出現，請聯絡系統管理員。",
            });
          }
          this.isSubmit = false;
          this.loadingVar = false;
          document.getElementById("contactForm").reset();
          this.phone = "";
        });
    },
    formVerify() {
      const email = document.querySelector("#email").value;
      //const phone = document.querySelector("#phone").value;
      const emailRule = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRule.test(email)) {
        this.$swal({
          icon: "error",
          title: "Email格式錯誤",
          text: "請輸入正確的Email格式，範例：test@gmail.com。",
        });
        return false;
      }
      if (!this.valid_phone) {
        this.$swal({
          icon: "error",
          title: "聯絡方式格式錯誤",
          text: "請輸入正確的聯絡方式格式，範例：0912345678。",
        });
        return false;
      }
      let formInputData = document.querySelectorAll(
        "#contactForm .form-control"
      );
      for (let i = 0; i < formInputData.length; i++) {
        if (formInputData[i].value == "") {
          this.$swal({
            icon: "error",
            title: "表單內有未填入的欄位",
            text: "請檢查表單內的欄位！",
          });
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style>
.input-tel__input,
.country-selector__input {
  background-color: #f2f9fc !important;
  height: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: 1px solid #e8e8e8 !important;
  box-shadow: none !important;
  caret-color: aquamarine !important;
}
.input-tel__input:focus,
.country-selector__input:focus {
  border: 1px solid #06b1d8 !important;
}
.fa-stack {
  color: #33ccff;
}

#contactus .container {
  max-width: 1000px;
}

.juc-card-icon {
  height: 96px;
}

.jsc-card-body {
  height: 360px !important;
  margin: 0px 10px;
}

.jsc-work-btn {
  height: 100px;
  font-size: 28px;
}

.jsc-work-btn a {
  color: #fff;
}

.jsc-card {
  margin-bottom: 30px;
}

.jsc-card .card {
  /* height: 32vh !important; */
  border: 1px solid #2680ff;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fa-external-link-alt:before {
  margin-left: 12px;
}

.juc-title h1 {
  font-size: 50px;
  color: #2680ff;
  margin-bottom: 30px;
}

.juc-card-icon i {
  margin-top: 30px;
  color: #2680ff;
  font-size: 4rem;
}

.jsc-icon-title {
  font-size: 34px;
  color: #2680ff;
  margin-bottom: 15px;
}

.jsc-content {
  font-size: 18px;
}

@media (max-width: 1200px) {
  /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {
  /* Large devices (desktops, 992px and down) */
  #joinus .container {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  #joinus .container {
    max-width: 600px;
  }

  /*  Medium devices (tablets, 768px and down) */
}

@media (max-width: 576px) {
  /*  Small devices (landscape phones, 576px and down) */
  .juc-card-icon i {
    margin-top: 5px;
  }

  #joinus .container {
    max-width: 400px;
  }
}
</style>
