var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "solutions-area section-width ptb-100",
      attrs: { id: "team" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "section-title" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_4_2")))])
        ]),
        _c(
          "div",
          { staticClass: "mot-images-group" },
          [
            _c(
              "b-row",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "6", "data-wow-delay": "0.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/涂建國.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_1.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_1.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "6", "data-wow-delay": "0.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/涂宇欣.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_3.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_3.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            ),
            _c("b-row", { staticClass: "mb-4" }),
            _c(
              "b-row",
              { staticClass: "mb-4" },
              [
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/盧品諭.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_11.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_11.name")) + " "
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/林素琴.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_6.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_6.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/專案總監-Renee_工作區域 1.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_13.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_13.name")) + " "
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/許力文.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_7.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_7.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/施廷榆.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_12.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_12.name")) + " "
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/陳俊佑.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_10.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_10.name")) + " "
                          )
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/余美慧.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_4.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_4.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/郭天羿.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_5.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_5.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "mb-4 block wow fadeInUp",
                    attrs: { sm: "12", md: "4", "data-wow-delay": "1.5s" }
                  },
                  [
                    _c("img", {
                      staticClass: "mot-image",
                      attrs: {
                        src: require("assets/project_images/pic/鄭順安.jpg"),
                        alt: "image"
                      }
                    }),
                    _c("div", { staticClass: "about-overlay" }, [
                      _c("div", { staticClass: "hover-text-container" }, [
                        _c("p", { staticClass: "about-hover-title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("card.item_9.title")) + " "
                          )
                        ]),
                        _c("p", { staticClass: "about-hover-content" }, [
                          _vm._v(" " + _vm._s(_vm.$t("card.item_9.name")) + " ")
                        ])
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }