<template>
  <div>
    <b-modal
      :id="`modal-news-${newsId}`"
      centered
      size="lg"
      hide-header
      @hidden="onHidden"
    >
      <div class="modal-body" :class="[news.length >= 0 ? 'flex-center' : '']">
        <b-spinner
          variant="primary"
          label="Spinning"
          v-if="news.length == 0"
        ></b-spinner>
        <div v-if="news.length != 0">
          <div class="popup">
            <h3>{{ year }}.{{ month }}.{{ day }}</h3>
            <h4 class="modal-title text-center mb-3">
              {{ news.message_title }}
            </h4>
          </div>
          <div class="card mb-3">
            <div class="card-body ck-content">
              <p v-html="news.message_content"></p>

              <!-- 附件區塊 -->
              <div class="attachments">
                <div
                  class="attachment-item"
                  v-for="(file, index) in news.attachment"
                  :key="index"
                >
                  <div class="icon">
                    <i class="bi bi-tag-fill"></i>
                  </div>
                  <span class="file-name">
                    {{
                      file.description ||
                        file.file_description ||
                        file.file_name
                    }}
                    <span class="file-ext"
                      >.{{ getFileExtension(file.file_name) }}</span
                    >
                  </span>
                  <i
                    class="bi bi-download clickable-icon download-icon"
                    @click="downloadFile(file.path, file.file_name)"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="text-right">
            <b-button variant="secondary" @click="toggleModal">關閉</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "NewsModal",
  props: {
    NewsModelList: {
      type: [Array, Object],
      default: () => [],
    },
    newsId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      year: "",
      month: "",
      day: "",
      news: [],
    };
  },
  computed: {
    getNewsData() {
      if (this.NewsModelList.length > 0) {
        let date = this.NewsModelList.post_date.split("-");
        let year = date[0];
        let month = date[1];
        let day = date[2].split(" ")[0].substring(0, 2);

        return [year, month, day];
      } else {
        return 0;
      }
    },
  },
  methods: {
    // 新增取得副檔名方法
    getFileExtension(filename) {
      if (!filename) return "";
      return filename
        .split(".")
        .pop()
        .toLowerCase();
    },
    toggleModal() {
      this.$root.$emit("bv::toggle::modal", `modal-news-${this.newsId}`);
    },
    onHidden() {
      this.news = [];
    },
    getFileIcon(type) {
      if (!type) return "bi bi-tag-fill";
      return "bi bi-tag-fill";
    },

    async downloadFile(fileUrl, fileName) {
      try {
        if (!fileUrl) {
          console.error("No file URL provided");
          return;
        }

        let downloadUrl = fileUrl;
        if (!downloadUrl.startsWith("http")) {
          downloadUrl = `/api${fileUrl.startsWith("/") ? "" : "/"}${fileUrl}`;
        }

        const response = await fetch(downloadUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || fileUrl.split("/").pop() || "download";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("下載失敗:", error);
      }
    },
  },
  watch: {
    NewsModelList: {
      handler(newValue) {
        this.news = this.NewsModelList;
        if (newValue && newValue.post_date) {
          const dateParts = newValue.post_date.split(" ")[0].split("-");
          if (dateParts.length === 3) {
            this.year = dateParts[0];
            this.month = dateParts[1].padStart(2, "0");
            this.day = dateParts[2].padStart(2, "0");
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.news-modal-title {
  font-size: 20px !important;
}

.spinner-border.text-primary {
  margin: 10rem 0;
}

.btn-secondary {
  background-color: #6c757d;
}

.popup h2,
h3 {
  color: #33ccff !important;
}

.popup h2 {
  text-align: center;
  font-weight: 100;
  font-size: 5.313em;
  letter-spacing: 3px;
  margin: 30px 0 0 0;
  line-height: 0.7em;
}

.popup h3 {
  text-align: center;
  font-weight: 100;
  font-size: 1.7em;
  margin: 10px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 附件區塊樣式 */
.attachments {
  margin-top: 1rem;
}

.attachment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #dee2e6;
  font-size: 14px;
}

.icon {
  margin-right: 0.5rem;
  font-size: 24px;
  color: #333;
}

.file-name {
  flex: 1;
  margin-left: 0.5rem;
  font-size: 18px;
  color: #333;
  word-break: break-all;
}

/* 新增副檔名樣式 */
.file-ext {
  color: #333;
  font-size: 18px;
}

.download-icon {
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
  margin-left: 1rem;
  transition: color 0.3s;
}
</style>

<style lang="css" src="assets/css/ckeditor.css"></style>
