<template>
    <div id="team" class="solutions-area section-width ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>{{ $t('navbar.menu_4_2') }}</h2>
            </div>
            <div class="mot-images-group">
                <b-row class="mb-4">
                    <b-col sm="12" md="6" class="mb-4 block wow fadeInUp" data-wow-delay="0.5s">
                        <img class="mot-image" src="~assets/project_images/pic/涂建國.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_1.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_1.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="6" class="mb-4 block wow fadeInUp" data-wow-delay="0.5s">
                        <img class="mot-image" src="~assets/project_images/pic/涂宇欣.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_3.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_3.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <!-- <b-col sm="12" md="6" class="mb-4 block wow fadeInUp " data-wow-delay="0.5">
                        <img class="mot-image" src="~assets/project_images/pic/涂宇欣.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_3.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_3.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col> -->
                </b-row>
                <b-row class="mb-4">

                </b-row>
                <b-row class="mb-4">
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/盧品諭.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_11.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_11.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/林素琴.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_6.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_6.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/專案總監-Renee_工作區域 1.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_13.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_13.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/許力文.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_7.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_7.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/施廷榆.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_12.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_12.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/陳俊佑.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_10.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_10.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/余美慧.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_4.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_4.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/郭天羿.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_5.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_5.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                    <b-col sm="12" md="4" class="mb-4 block wow fadeInUp" data-wow-delay="1.5s">
                        <img class="mot-image" src="~assets/project_images/pic/鄭順安.jpg" alt="image">
                        <div class="about-overlay">
                            <div class="hover-text-container">
                                <p class="about-hover-title">
                                    {{ $t('card.item_9.title') }}
                                </p>
                                <p class="about-hover-content">
                                    {{ $t('card.item_9.name') }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'JoinUsContent',
    data() {
        return {}
    },
}
</script>

<style scoped>
.solutions-area .container {
    max-width: 1000px;
}

.mot-title {
    text-align: justify;
    text-justify: inter-ideograph;
    color: #2680FF;
    font-weight: bold;
    font-size: 50px !important;
}

.mot-text {
    font-size: 18px !important;
    text-align: justify;
    text-justify: inter-ideograph;
}

.mot-image {
    height: 100%;
    object-fit: cover;
}

.mot-images-group .block .about-overlay {
    opacity: 1;
    height: 100%;
}

.block {
    text-align: center;
}

.about-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    transition: 0.5s ease;
}

.about-hover-title {
    font-size: 22px;
    color: black;
    position: absolute;
    bottom: 40px;
    font-weight: 500;
    text-shadow: 1px 1px 1px #ffffff;
}

.about-hover-content {
    color: white;
    position: absolute;
    bottom: 10px;
    font-size: 33px;
    text-shadow: 1px 1px 5px #383838;
}

.hover-text-container {
    padding-left: 10px;
    width: 90%;
    height: 100%;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: left;
}

.mot-container {
    max-width: 1400px;
}

@media (max-width: 1200px) {
    /* Extra large devices (large desktops, 1200px and down) */
}

@media (max-width: 992px) {

    /* Large devices (desktops, 992px and down) */
    .about-hover-title {
        font-size: 1.365em;
        position: absolute;
        bottom: 30px;
        padding-bottom: 5px;
    }

    .about-hover-content {
        color: white;
        position: absolute;
        bottom: 10px;
        font-size: 1.465em;
    }
}

@media (max-width: 768px) {

    /*  Medium devices (tablets, 768px and down) */
    .hover-text-container {
        padding-left: 80px;
    }

    .about-hover-title {
        font-size: 25px;
        position: absolute;
        bottom: 50px;
        padding-bottom: 5px;
    }

    .about-hover-content {
        color: white;
        position: absolute;
        bottom: 10px;
        font-size: 38px;
    }
}

@media (max-width: 576px) {
    .mot-image {
        min-width: auto !important;
        min-height: auto !important;
    }

    .mot-images-group {
        width: auto;
        margin: 100px 0;
    }

    .hover-text-container {
        padding-left: 10px;
    }
}
</style>