<template>
  <div class="bg-color">
    <Navbar />
    <Header
      :category="'press'"
      :bannerTitle="bannerTitle"
      :breadCrumb="breadCrumb"
    />
    <NewsBody />
    <FooterTop />
    <FooterBottom />
  </div>
</template>

<script>
import Navbar from "components/DefaultHome/KECC/Common/NavbarStyle";
import Header from "components/DefaultHome/KECC/Common/Header";
import NewsBody from "./NewsBody";
import FooterTop from "components/DefaultHome/KECC/Common/FooterTop?v=1.0";
import FooterBottom from "components/DefaultHome/KECC/Common/BacktopButton";

export default {
  name: "JoinUsView",
  metaInfo() {
    return {
      title: this.$t("navbar.menu_3_1"),
    };
  },
  components: {
    Navbar,
    Header,
    FooterTop,
    FooterBottom,
    NewsBody,
  },
  data() {
    return {
      bannerTitle: "press",
      breadCrumb: [
        {
          name: this.$t("navbar.menu_3"),
        },
      ],
    };
  },
};
</script>

<style>
.bg-color {
  background-color: #eff4f5;
}
</style>
