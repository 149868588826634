var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-news-" + _vm.newsId,
            centered: "",
            size: "lg",
            "hide-header": ""
          },
          on: { hidden: _vm.onHidden }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-body",
              class: [_vm.news.length >= 0 ? "flex-center" : ""]
            },
            [
              _vm.news.length == 0
                ? _c("b-spinner", {
                    attrs: { variant: "primary", label: "Spinning" }
                  })
                : _vm._e(),
              _vm.news.length != 0
                ? _c("div", [
                    _c("div", { staticClass: "popup" }, [
                      _c("h3", [
                        _vm._v(
                          _vm._s(_vm.year) +
                            "." +
                            _vm._s(_vm.month) +
                            "." +
                            _vm._s(_vm.day)
                        )
                      ]),
                      _c(
                        "h4",
                        { staticClass: "modal-title text-center mb-3" },
                        [_vm._v(" " + _vm._s(_vm.news.message_title) + " ")]
                      )
                    ]),
                    _c("div", { staticClass: "card mb-3" }, [
                      _c("div", { staticClass: "card-body ck-content" }, [
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(_vm.news.message_content)
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "attachments" },
                          _vm._l(_vm.news.attachment, function(file, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "attachment-item" },
                              [
                                _c("div", { staticClass: "icon" }, [
                                  _c("i", { staticClass: "bi bi-tag-fill" })
                                ]),
                                _c("span", { staticClass: "file-name" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        file.description ||
                                          file.file_description ||
                                          file.file_name
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "file-ext" }, [
                                    _vm._v(
                                      "." +
                                        _vm._s(
                                          _vm.getFileExtension(file.file_name)
                                        )
                                    )
                                  ])
                                ]),
                                _c("i", {
                                  staticClass:
                                    "bi bi-download clickable-icon download-icon",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloadFile(
                                        file.path,
                                        file.file_name
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "secondary" },
                            on: { click: _vm.toggleModal }
                          },
                          [_vm._v("關閉")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }