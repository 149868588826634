<template>
  <div class="partner-area ptb-64">
    <div class="container">
      <div class="partner-slider-six owl-carousel owl-theme">
        <div class="row">
          <div class="col-lg-2">
            <h3>{{ $t("link.title_1") }}</h3>
          </div>
          <div class="col-lg-10">
            <div class="row">
              <div class="col-lg-2 col-sm-6 team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/pic/logo-list-1.png"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 col-sm-6 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/pic/logo-list-3.png"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 col-sm-6 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/svg/安益集團_Logo_3.svg"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 col-sm-6 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/svg/安益集團_Logo_4.svg"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 col-sm-6 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/pic/logo-list-5.png"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 col-sm-6 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/pic/logo-list-6.png"
                      alt="Image"
                    />
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="partner-slider-six owl-carousel owl-theme mt-5">
        <div class="row">
          <div class="col-lg-2">
            <h3>{{ $t("link.title_2") }}</h3>
          </div>
          <div class="col-lg-10">
            <div class="row">
              <div class="col-lg-2 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/pic/UFI_Member-logo-06AB23A13E-seeklogo.com.png"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 text-center team-logo-img">
                <div class="partner-item">
                  
                    <img
                      src="~assets/project_images/pic/ICCA.png"
                      alt="Image"
                    />
                  
                </div>
              </div>
              <div class="col-lg-2 text-center team-logo-img">
                <div class="partner-item">
                    <img
                      src="~assets/project_images/pic/teca-logo.png"
                      alt="Image"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickLink",
};
</script>

<style scoped>
/*
    .partner-item{
        text-align: center;
        background: #fff;
        margin-bottom: 20px;
        padding: 37px 15px 40px 8px;
        -moz-transition: .4s;
        -webkit-transition: .4s;
        transition: .4s;
        -webkit-box-shadow: 0 -2px 20px 0 rgb(0 0 0 / 6%);
        box-shadow: 0 -2px 20px 0 rgb(0 0 0 / 6%);
    }*/
.partner-item img {
  object-fit: contain;
  width: 150px;
  height: 150px;
}
.partner-area .container {
  max-width: 1400px;
}

.ptb-64 {
  padding-top: 65px;
  padding-bottom: 45px;
}

.col-lg-2 h3 {
    font-size: 25px;
    text-transform: capitalize;
    font-weight: 500;
}

img {
  max-width: 150px;
  max-height: 100px;
}
</style>
